export const TABS_VALUE = {
  transaction: 1,
  performance: 2,
};

export const FAQS = [
  {
    icon: require("assets/vectors/trackOrder/faq_1.svg"),
    title: "What are the payment options available?",
    description:
      "We support alll sorts of payment options, be it UPI, netbanking, debit card or credit cards.",
  },
  {
    icon: require("assets/vectors/trackOrder/faq_3.svg"),
    title: "Payment failed, but money got deducted?",
    description:
      "No need to worry! Banks usually take up to 3 business days to add money back to your account. Please wait for your bank to complete the reversal. Refer to the bank account statement to verify if your transaction has been reversed. If money is not added within 3 business days, please reach out to us.",
  },
  {
    icon: require("assets/vectors/trackOrder/faq_2.svg"),
    title: "Payment went into processing state?",
    description:
      "No need to worry! Wait for 3 business days, within which the bank should add the money to the recipient account or reverse the same to your account. If money is not added within 3 business days, please reach out to us.",
  },
  {
    icon: require("assets/vectors/trackOrder/faq_1.svg"),
    title:
      "Payment is successful but did not receive any SMS confirmation of booking?",
    description:
      "This is due to telecom network issue. It is suggested to wait for atleast 24 hours in such cases. If you still don't receive any confrimation, please reach out to us.",
  },
];
