import React from "react";
import style from "../../Style.module.css";
import ChevronRight from "@material-ui/icons/ChevronRight";

export const Faqcard = ({ faqItem }) => {
  const [show_more, set_show_more] = React.useState(false);

  return (
    <div className={`${style.faqCard}`}>
      <div>
        <img
          className={`${style.trackIcon}`}
          src={faqItem?.icon}
          alt="faqIcon"
        />
      </div>

      <div className={`ml-2`}>
        <div>{faqItem.title}</div>
        <div className={`${style.greyText} ${show_more ? "" : "line_max_3"}`}>
          {faqItem.description}
        </div>
        <div
          onClick={() => set_show_more(!show_more)}
          className={`${style.faqCta} pointer`}
        >
          {show_more ? "Hide" : "Learn more"} <ChevronRight />
        </div>
      </div>
    </div>
  );
};
