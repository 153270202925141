import React from "react";
import ExlyModal from "common/Components/ExlyModal";
import styles from "./InitiateRefund.module.css";
import classnames from "classnames";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import { isCustomRefundAmountType } from "../../utils/AllTransaction.util";
import { TRANSACTIONS_REFUND_OPTIONS } from "../../transactions.constants";
import { InitiateRefundModalContext } from "../../AllTransaction";
import { NOTES } from "./constants/ConfirmationInitiateRefundModal.data";

const ConfirmationInitiateRefundModal = ({
  open,
  onClose,
  onConfirm,
  handleOnBackClick,
}) => {
  const { selectedRefundType } = React.useContext(InitiateRefundModalContext);
  const isPartialRefund = isCustomRefundAmountType(
    TRANSACTIONS_REFUND_OPTIONS[selectedRefundType]
  );

  return (
    <ExlyModal
      title="Are you sure you want to refund ?"
      open={open}
      onClose={onClose}
      primaryBtnText="Proceed"
      onPrimaryBtnClick={onConfirm}
      secondaryBtnText="Back"
      onSecondaryBtnClick={handleOnBackClick}
      modal_props={{
        modalPaperClassName: classnames(
          styles.modalContainer,
          styles.modalContainerHeight
        ),
      }}
    >
      <span className={classnames(styles.noteInfoContainer, "loader-wrap")}>
        Note: {isPartialRefund ? NOTES.partial_refund_note : NOTES.refund_note}
      </span>
    </ExlyModal>
  );
};

export default withComponentLibraryTheme(ConfirmationInitiateRefundModal);
