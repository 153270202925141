import {
    List,
    Datagrid,
    TextField, FunctionField, Filter, TextInput, DateInput, BooleanField, Pagination, SelectInput, useListController, NumberInput
} from 'react-admin';
import React from 'react';
import moment from 'moment';
import dataProvider from '../../../../data/dataProvider';
import style from '../Style.module.css';
import { is_empty } from '../../../../utils/validations';
import { parsePhoneNumber } from '../../../../utils/Utils';
import Modal from '@material-ui/core/Modal';
import { useRef } from "react";
import Alert from '../../../modules/Alert';
import EmptyList from '../../../modules/EmptyList';
import TransactionHeader from "../../../modules/headers/TransactionHeader";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import constants from '../../../../constants/constants';
import SearchSharpIcon from '@material-ui/icons/SearchSharp';
import { InputAdornment } from '@material-ui/core';
import { getParameterByName } from '../../../../utils/Utils';
import api from "../../../../data/APIs";
import { getUserCurrencySymbol } from '../../../../utils/AuthUtil';
import { useNotifications } from 'utils/hooks';

const EligibleRefunds = (props) => {

    const { filterValues, loaded, data } = useListController(props);

    const ref = useRef(null);


    const { pushNotification } = useNotifications();

    const [showAlert, setShowAlert] = React.useState(false);
    const [clicked, setClicked] = React.useState(false);
    const [selectedRecordData, setSelectedRecordData] = React.useState('');


    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setShowAlert(false);
        }
    };

    const ActionField = ({ record = {}, source }) => {
        return (<a onClick={() => {
            setSelectedRecordData(record);
            setShowAlert(true);
        }
        } href={record[source]} className='action_text'>
            Initiate Refund
        </a>);
    }

    const MoneyField = ({ record = {}, source }) => {
        return (<span>
            {`${getUserCurrencySymbol()} ${record[source]}`}
        </span>);
    }

    const PhoneField = ({ record = {}, source }) => {
        const phone_number = parsePhoneNumber(record, {countryCodeKey: "country_code", phoneNumberKey: source});
        return (<span>
            { is_empty(record[source]) ? 'N/A' : <a
                target="_blank"
                rel="noopener noreferrer"
                className={style.whatsapp_number}
                href={`https://api.whatsapp.com/send?phone=${phone_number.substring(1)}`}>
                {phone_number}
                <WhatsAppIcon className={style.whatsapp_icon} />
            </a>
            }
        </span>);
    }

    const ListPagination = props => <Pagination rowsPerPageOptions={[]} {...props} />;


    const PostFilter = (props) => (
        <>
            <Filter {...props}>
                <TextInput variant="outlined" className={`mobile_filter`} label="Name" source="booked_user_name" alwaysOn resettable
                    InputProps={{
                        endAdornment: <InputAdornment position="end"> <div className={style.searchIcon}><SearchSharpIcon /></div></InputAdornment>}}
                />
                <TextInput variant="outlined" className={`mobile_filter`} label="Email" source="booked_user__email__icontains" alwaysOn resettable InputProps={{
                endAdornment: <InputAdornment position="end"> <div className={style.searchIcon}><SearchSharpIcon /></div></InputAdornment>}} />
                <TextInput variant="outlined" className={`mobile_filter`} label="Mobile number" source="booked_user__phone_number__contains" alwaysOn resettable InputProps={{
                endAdornment: <InputAdornment position="end"> <div className={style.searchIcon}><SearchSharpIcon /></div></InputAdornment>}} />
                <SelectInput variant="outlined" className={`mobile_filter`} label="Session Type" choices={constants.schedule_type}
                    source='listing__type' alwaysOn optionValue="id" />
                <DateInput variant="outlined" className={`mobile_filter`} label="From" source="slot__start_datetime__gte" alwaysOn />
                <DateInput variant="outlined" className={`mobile_filter`} label="To" source="slot__start_datetime__lte" alwaysOn />
                <NumberInput variant="outlined" className={`mobile_filter`} label="Minimum Amount" source="transaction__payble_amount__gte" alwaysOn resettable InputProps={{
                endAdornment: <InputAdornment position="end"> <div className={style.searchIcon}><SearchSharpIcon /></div></InputAdornment>}} />
                <NumberInput variant="outlined" className={`mobile_filter`}label="Maximum Amount" source="transaction__payble_amount__lte" alwaysOn resettable InputProps={{
                endAdornment: <InputAdornment position="end"> <div className={style.searchIcon}><SearchSharpIcon /></div></InputAdornment>}} />
                <TextInput variant="outlined" label="Session Name" source="listing__title__icontains" alwaysOn resettable InputProps={{
                endAdornment: <InputAdornment position="end"> <div className={style.searchIcon}><SearchSharpIcon /></div></InputAdornment>}} />
            </Filter>
        </>
    );

    return (
        <div className={'parent'}>

            <div className={` ${style.sticky}`}>
                <TransactionHeader active={1} />
            </div>


            {is_empty(data) && loaded && is_empty(props.location.search) && is_empty(filterValues) ? <EmptyList module={'transaction'} module_text={'refund'} /> : <>{!is_empty(filterValues) || !is_empty(getParameterByName('custom_filter')) ?
                <div className={style.clear_filter} onClick={() => {
                    window.location.href = `${window.location.origin}/#/payments/exly/refunds/eligible/list`;
                    window.location.reload();
                }} ><span className='text-danger'>Clear Filter</span></div> : null}
                <List perPage={50}{...props} bulkActionButtons={false}
                    pagination={<ListPagination />}
                    filters={<PostFilter />}
                >
                    <Datagrid isRowSelectable={record => false} >
                        <TextField variant="outlined" source="customer_name" emptyText={'N/A'} />
                        <PhoneField variant="outlined" label={'Phone'} source="phone_number" emptyText={'N/A'} />
                        <ActionField variant="outlined" label="Action" />
                        <TextField variant="outlined" source="session_name" emptyText={'N/A'} />
                        <TextField variant="outlined" source="customer_email" emptyText={'N/A'} />
                        <FunctionField variant="outlined" label="Session Date" render={record => `${moment.unix(record.session_date).utc().format('MMM DD, YYYY, hh:mm A')}`} />
                        <MoneyField variant="outlined" source="amount" emptyText={'N/A'} />
                        <BooleanField variant="outlined" source="discounted" addLabel={true} label={"Discount Used?"} emptyText={'N/A'} />
                    </Datagrid>
                </List>

                <Modal open={showAlert} onClick={handleClickOutside}>

                    <Alert primary_cta_text={'Confirm'} secondary_cta_text={'Cancel'} body={`You are refunding ${getUserCurrencySymbol()} ${selectedRecordData.amount} to ${selectedRecordData.customer_name} for ${selectedRecordData.session_name} booked on ${moment.unix(selectedRecordData.session_date).utc().format('DD/MM/YY hh:mm A')}`} header={'Confirm Refund'}
                        primary_action={async () => {
                            try {
                                if (is_empty(selectedRecordData) || is_empty(selectedRecordData.id) || clicked) return;
                                await setClicked(true);
                                const status = await dataProvider.custom_request(api.initiate_refund, "POST", { booking_id: selectedRecordData.id });
                                pushNotification(status.message, { variant: "outlined", color: "primary" });
                                setShowAlert(false);
                                if (status.status === 200) {
                                    window.location.reload();
                                }
                            }
                            catch (err) {
                                pushNotification(err.body.message, { variant: "outlined", color: "coral_red" });
                            }
                        }} secondary_action={() => {
                            setShowAlert(false);
                        }} />
                </Modal>
            </>
            }
        </div>
    );
}

export default EligibleRefunds;