import { useEffect, useMemo, useRef, useState } from "react";
import constants from "constants/constants";
import {
  notification_color_keys,
  useNotifications,
  useToggleState,
} from "utils/hooks";
import { is_empty } from "utils/validations";
import {
  STATUS,
  TRANSACTIONS_REFUND_OPTIONS,
  TRANS_CONSTANTS,
  POSTFIX_RETURN_URL,
} from "../transactions.constants";
import { dataProvider } from "data";
import { TRANSACTION_APIS } from "ui/pages/transactions/transactions.apis";
import { apiMethods } from "data/api.constants";
import { isRequestSuccessful } from "utils/Utils";
import { getRAUrlParams } from "utils/urlUtils";
import { useListController, useRefresh } from "react-admin";
import { initiateJuspay } from "utils/Payments/PaymentUtil";
import { BOOKINGS_STATUS_CODES } from "constants/schedule";
import { ORDER_PAYMENT_TYPE } from "schedule-v2/constants";
import {
  BAJAJ_FINANCE_PAYMENT_GATEWAY,
  GATEWAY_TYPE,
} from "features/Crm/modules/Transactions/Transactions.constants";
import SessionStorageConstants from "constants/SessionStorage.constants";
import { field_data } from "../AllTransactionTableConfig";
import { REFUND_PAGES } from "features/Crm/modules/Transactions/modules/Refund/Refund.constants";
import {
  isCustomRefundAmountType,
  validateRefundAmount,
} from "../utils/AllTransaction.util";
import { DEFAULT_PER_PAGE } from "constants/numbers";
import { checkIsDefined } from "features/Common/modules/DataTypes/utils/nanNullUndef";
import { getPermissions } from "utils/AuthUtil";
import { orgPermissions } from "utils/OrgPermissions";
import { REQUEST_ABORT_SIGNALS } from "features/Transactions/modules/GSTDetailsField/constants/Transactions.constants";

export const useTransactionUpdate = (props) => {
  const { notify } = useNotifications();
  const refresh = useRefresh();
  const {
    onSelect,
    data,
    ids,
    page,
    setFilters,
    filterValues: currentFilters,
  } = useListController({ ...props, perPage: DEFAULT_PER_PAGE });

  // states
  const [isLoading, setIsLoading] = useState(false);
  const [
    isOrgPermissionsLoading,
    startLoadingOrgPermissions,
    stopLoadingOrgPermissions,
  ] = useToggleState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [isOpenInitiateRefundModal, setisOpenInitiateRefundModal] = useState({
    refundModal: false,
    confirmModal: false,
  });
  const [selectedRefundType, setSelectedRefundType] = useState("");
  const [refundAmountData, setRefundAmountData] = useState({});
  const [selectedRefundRecords, setSelectedRefundRecords] = useState();
  const [refundRequestId, setRefundRequestId] = useState(null);
  const [activeRefundPage, setActiveRefundPage] = useState(
    REFUND_PAGES.reviewSelection
  );
  const [selectedfilteredIds, setSelectedFilteredIds] = useState([]);
  const [customRefundAmountState, setCustomRefundAmountState] = useState({
    value: undefined,
    error: null,
  });
  //This state contains the data of all the selected records across pages of the paginated Transactions list
  const [selectedRecords, setSelectedRecords] = useState({});
  // extract the APIs
  const { validate_refunds, initiate_refunds, status_refund_order } =
    TRANSACTION_APIS;

  const abortControllerRef = useRef(null);

  const getFirstTransactionPaymentAmount = () => {
    const [selectedTransaction] = Object.values(selectedRecords);
    const { payable_amount: payableAmount } = selectedTransaction;
    return payableAmount;
  };

  /**
   * initiated when user select dropdown values when he try to initiate the refund process
   * @param {*} uuids (transactionUuids): selected transaction uuids
   * @param {*} val (selectedRefundType): selected refund type (only LP, include/exclude IHF) by dropdowns value
   * @returns json response
   */
  const validateRefunds = async ({ uuids, val, refundAmount }) => {
    try {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort(REQUEST_ABORT_SIGNALS.validate_refund);
        abortControllerRef.current = null;
      }

      if (!is_empty(uuids) && !is_empty(val)) {
        setIsLoading(true);

        const payload = {
          transaction_uuids: uuids,
          refund_option: TRANSACTIONS_REFUND_OPTIONS[val],
        };

        if (isCustomRefundAmountType(TRANSACTIONS_REFUND_OPTIONS[val])) {
          payload.refund_amount = +(refundAmount ?? 0);
          const error = validateRefundAmount(
            payload.refund_amount,
            getFirstTransactionPaymentAmount()
          );

          if (error) return;
        }

        const abortController = new AbortController();
        abortControllerRef.current = abortController;

        const response = await dataProvider.custom_request(
          `${validate_refunds}`,
          apiMethods.POST,
          payload,
          false,
          abortController.signal
        );

        const { data, status } = response;
        if (isRequestSuccessful(status)) {
          setRefundAmountData(data);
        }
      }
    } catch (err) {
      if (err === REQUEST_ABORT_SIGNALS.validate_refund) return;

      console.log("Error on slection of Refund Type", err);
      notify(
        err?.body?.message ?? constants.error_message,
        notification_color_keys.error
      );
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * handle to get the payment status after the transactions
   */
  const getPaymentOrderStatus = async () => {
    try {
      const response = await dataProvider.custom_request(
        `${status_refund_order}/${getRAUrlParams().get(TRANS_CONSTANTS.order)}`,
        apiMethods.GET
      );
      const { data } = response;
      if (isRequestSuccessful(response.status))
        setPaymentStatus(parseInt(data.order.status));
    } catch (error) {
      console.log("Error on payment status modal", error);
    }
  };

  /**
   * initiate the refund process and open payment gateway
   * @param {*} transactionUuids: selected transaction uuids
   * @param {*} selectedRefundType: selected refund type (only LP, include/exclude IHF) by dropdowns value
   */
  const initiateRefund = async (transactionUuids, selectedRefundType) => {
    try {
      if (!is_empty(transactionUuids) && !is_empty(selectedRefundType)) {
        const status = await dataProvider.custom_request(
          `${initiate_refunds}`,
          apiMethods.POST,
          {
            transaction_uuids: transactionUuids,
            refund_option: TRANSACTIONS_REFUND_OPTIONS[selectedRefundType],
            return_url: `${window.location.origin}/#/${POSTFIX_RETURN_URL}`,
            refund_amount: checkIsDefined(customRefundAmountState.value)
              ? +customRefundAmountState.value
              : undefined,
          }
        );

        if (isRequestSuccessful(status.status)) {
          // if there is no amount payble / no payout goes to creator (amount is received by us:Exly)
          // no need to open payment gateway and open the success modal and initate the refund
          if (status?.data?.gateway === TRANS_CONSTANTS.free) {
            setisOpenInitiateRefundModal({
              refundModal: false,
              confirmModal: false,
            });
            // open the successful modal (status:2)
            setPaymentStatus(BOOKINGS_STATUS_CODES.RECEIVED.id);
            setSelectedRefundType("");
            setRefundAmountData({});
            onSelect([]);
            setSelectedRecords({});
            setSelectedRefundRecords({});
            refresh();
          } else initiateJuspay(status.data?.order);
        } else {
          notify(constants.error_message, notification_color_keys.error);
        }
      }
    } catch (err) {
      console.log("Error in opening of payment gateway", err);
      notify(
        err?.body?.message ?? constants.error_message,
        notification_color_keys.error
      );
    }
  };

  /**
   * handle selection of row according to requirements as follows:
   * payment type: enable selection when payment type is one time payment and Subscription type
   * status: disable selection for refunded/cancelled status
   * payoutStatus: if payoutStatus is added booking (by cash) and refund Initiated disable selection
   * isInternational: if customer is international disable the selection (currently not handling refund for intrnational)
   * transactionAmount: disabling the selection (can not initiate refund) if transaction amount is 0 (offering amount is free)
   *
   * incase the rows array contains any id of a record which is not on current page, then we do not need to
   * validate the above checks for them, as it would have happened once already.
   * @param {array} rows: array of all the selected rows
   */
  const getFilteredRowsFromSelection = (rows) => {
    let selectedRows = { ...selectedRecords };

    // Incase the row was selected earlier, but now it is disselected, we need to remove it
    // If a record is present on current page, is in our selected records state, but not present
    // in the rows array, then it is disselected
    Object.keys(selectedRows).forEach((key) => {
      if (data[key] && !rows.includes(key)) {
        delete selectedRows[key];
      }
    });

    rows.forEach((rowIndex) => {
      const {
        status,
        payout_status: payoutStatus,
        payment_type: paymentType,
        is_international: isInternational,
        gateway,
      } = data[rowIndex] || {};

      // check if refunded or cancelled
      const checkStatus = [
        TRANS_CONSTANTS.refund,
        TRANS_CONSTANTS.cancelled,
      ].includes(STATUS[status]);

      // check the payout status
      const checkPayoutStatus = [TRANS_CONSTANTS.refund_initiated].includes(
        payoutStatus
      );

      // this check which customer have to select for refund process according to the requirements (define above)
      const isSelectedRow = checkStatus || checkPayoutStatus;

      const isBajajFinanceBooking = gateway === BAJAJ_FINANCE_PAYMENT_GATEWAY;

      // check the payment type (allowed selection for one time payment and subscription type)
      const checkPaymentType = [
        ORDER_PAYMENT_TYPE.ONE_TIME_PAYMENT,
        ORDER_PAYMENT_TYPE.SUBSCRIPTION,
      ].includes(paymentType);

      // check requirement condition (define above), if satisfiled the condition,
      // filtered the selected rows number and make an array "selectedRows"
      // currently there is only one option on rows select i.e. Initiate Refund
      if (
        checkPaymentType &&
        !isInternational && // currently we are not handling refund for international customers, i.e. also disabling the selection
        !isSelectedRow &&
        !isBajajFinanceBooking
      ) {
        selectedRows[rowIndex] = data[rowIndex];
      }
    });
    return selectedRows;
  };

  /**
   * handle row select according to condition
   * @param {array} rows: selected rows
   */
  const handleRowsSelected = (rows) => {
    if (!rows.length) {
      onSelect([]);
      setSelectedRecords({});
      setSelectedRefundRecords({});
      setSelectedFilteredIds([]);
      return;
    }
    const selectedRows = getFilteredRowsFromSelection(rows);
    // on first click (header checkbox) we are setting the selectedfilteredIds by filtered values
    // on second click (header checkbox) we are deselecting all the rows
    // and set selectedfilteredIds and selectedIds empty

    const selectedRowsIds = Object.keys(selectedRows);
    if (
      selectedRowsIds.length === selectedfilteredIds.length &&
      rows.length === ids.length
    ) {
      onSelect([]);
      setSelectedRecords({});
      setSelectedFilteredIds([]);
      return;
    }
    setSelectedRecords({ ...selectedRows });
    setSelectedRefundRecords({ ...selectedRows });
    setSelectedFilteredIds(selectedRowsIds);
    onSelect(rows.filter((rowId) => selectedRows?.[rowId]));
  };

  /**
   * On page change, we have to maintain the select all checkbox
   * and individual row selected check. This useEffect handles that.
   * furthurmore, initially on page change when we have some selected values
   * on other page, but not on current page, we need to show the table header checkbox
   * in intermediate state, for that we need to send atleast one value of selected record as per
   * ExlyTable's logic
   */
  useEffect(() => {
    if (!is_empty(data) && !is_empty(selectedRecords)) {
      const selectedRecordsValue = Object.keys(data).filter(
        (dataIndex) => selectedRecords[dataIndex]
      );
      const singleSelectedValue = Object.keys(selectedRecords).find(
        (selectedIndex) => !data[selectedIndex]
      );
      onSelect(
        !is_empty(selectedRecordsValue)
          ? selectedRecordsValue
          : singleSelectedValue
      );
    }
  }, [page, data]);

  const handleResetCustomRefundAmountState = () => {
    setCustomRefundAmountState({
      value: undefined,
      error: null,
    });
  };

  useEffect(() => {
    const handleInitOrgPermissions = () => {
      startLoadingOrgPermissions();
      getPermissions({ forceCall: true })
        .then(orgPermissions.init)
        .finally(stopLoadingOrgPermissions);
    };

    handleInitOrgPermissions();
  }, [orgPermissions]);

  // handle modal close of initiate refund
  const handleRefundModalClose = () => {
    setisOpenInitiateRefundModal({
      ...isOpenInitiateRefundModal,
      refundModal: false,
    });
    setSelectedRefundType("");
    setRefundAmountData({});
    setRefundRequestId(null);
    setActiveRefundPage(REFUND_PAGES.reviewSelection);
    setSelectedRefundRecords(selectedRecords);
    sessionStorage.removeItem(SessionStorageConstants.REFUND_REQUEST_ID); // to prevent same api being hit on navigating back to ReviewSelection, REFUND_REQUEST_ID is removed on modal close
    handleResetCustomRefundAmountState();
  };

  /**
   * handle the selection of refund type dropdown values and call the validate api
   * @param {*} selectedVal: selected refund type value from refund modal
   */
  const handleRefundOptionsSelection = (selectedVal) => {
    setSelectedRefundType(selectedVal);

    if (isCustomRefundAmountType(TRANSACTIONS_REFUND_OPTIONS[selectedVal]))
      setRefundAmountData({});

    // collecting the transaction ids for the validate API payload
    let selectedTransactionUuids = [];
    for (const id in selectedRefundRecords) {
      selectedTransactionUuids.push(selectedRefundRecords[id].uuid);
    }
    // calling the validate API for getting the data for refund process
    validateRefunds({
      uuids: selectedTransactionUuids,
      val: selectedVal,
    });
    handleResetCustomRefundAmountState();
  };

  // handle click on proceed of confirmation modal
  const handlePayNow = () => {
    const isCustomRefund = isCustomRefundAmountType(
      TRANSACTIONS_REFUND_OPTIONS[selectedRefundType]
    );

    if (isCustomRefund) {
      const error = validateRefundAmount(
        customRefundAmountState.value,
        getFirstTransactionPaymentAmount()
      );

      if (error) {
        notify(error, notification_color_keys.error);
        setCustomRefundAmountState((prev) => ({ ...prev, error }));
        return;
      }
    }

    setisOpenInitiateRefundModal({
      refundModal: false,
      confirmModal: true,
    });
  };
  // handle close of confirmation modal
  const confirmModalClose = () => {
    setSelectedRefundType("");
    setRefundAmountData({});
    setisOpenInitiateRefundModal({
      ...isOpenInitiateRefundModal,
      confirmModal: false,
    });
  };
  // handle click on back button of confirmation modal
  const handleOnBackClick = () => {
    setisOpenInitiateRefundModal({ refundModal: true, confirmModal: false });
  };

  const handleSetCustomRefundAmount = (value) => {
    const refundAmountNewState = { value, error: null };

    refundAmountNewState.error = validateRefundAmount(
      value,
      getFirstTransactionPaymentAmount()
    );

    setCustomRefundAmountState(refundAmountNewState);
  };

  const handleSetFilter = (filters) => {
    const {
      [field_data.transaction_id]: currentTransactionId,
      [field_data.gateway_type]: currentGatewayType,
    } = currentFilters;
    const {
      [field_data.transaction_id]: newTransactionId,
      [field_data.gateway_type]: newGatewayType,
    } = filters;

    /**
     * In ‘Transactions’ table, if a creator searches by ‘Transaction ID’ then:
     * - We will be auto-adding ‘Booking type: Exly Booking’ filter in combination with the transaction id filter.
     * - If the creator removes any of these two filters: ‘Transaction ID’ or ‘Booking type’ filter, then we will remove the transaction id filter as well.
     */
    if (newTransactionId && is_empty(currentGatewayType)) {
      setFilters({ ...filters, [field_data.gateway_type]: GATEWAY_TYPE.EXLY });
    } else if (
      (newTransactionId && currentGatewayType && is_empty(newGatewayType)) ||
      (currentTransactionId &&
        is_empty(newTransactionId) &&
        !is_empty(newGatewayType))
    ) {
      setFilters({
        ...filters,
        [field_data.gateway_type]: undefined,
        [field_data.transaction_id]: undefined,
      });
    } else {
      setFilters(filters);
    }
  };

  // handle get table action
  const getTableActions = useMemo(() => {
    const sendMessageButtonProps = {
      onClick: () =>
        setisOpenInitiateRefundModal({
          ...isOpenInitiateRefundModal,
          refundModal: true,
        }),
      text: `${TRANS_CONSTANTS.initiate_refund} (${
        Object.keys(selectedRecords).length
      })`,
    };
    let data = [];
    data.push(sendMessageButtonProps);
    return data;
  }, [selectedRecords]);

  const refundButtonDisabled = isLoading || is_empty(selectedRefundRecords);

  return {
    validateRefunds,
    isLoading,
    isOrgPermissionsLoading,
    getPaymentOrderStatus,
    paymentStatus,
    setPaymentStatus,
    initiateRefund,
    isOpenInitiateRefundModal,
    setisOpenInitiateRefundModal,
    handleRowsSelected,
    handleRefundModalClose,
    selectedRefundType,
    setSelectedRefundType,
    refundAmountData,
    setRefundAmountData,
    selectedRefundRecords,
    setSelectedRefundRecords,
    refundRequestId,
    setRefundRequestId,
    handleRefundOptionsSelection,
    handlePayNow,
    confirmModalClose,
    handleOnBackClick,
    getTableActions,
    selectedRecords,
    setSelectedRecords,
    activeRefundPage,
    setActiveRefundPage,
    refundButtonDisabled,
    customRefundAmountState,
    handleSetCustomRefundAmount,
    handleSetFilter,
  };
};
