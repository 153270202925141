// Third party imports
import React, { useState, useEffect, useRef, Fragment } from "react";
import Modal from "@material-ui/core/Modal";
import { useHistory } from "react-router-dom";

// Styles and icons
import useStyles from "./Subscriptions.styles";

// Utils and providers
import { is_empty } from "../../../../utils/validations";
import {
  orgPermissions,
  RESOURCE_KEYS,
} from "../../../../utils/OrgPermissions";
import { getUserCurrencySymbol } from "../../../../utils/AuthUtil";
import dataProvider from "../../../../data/dataProvider";
import api from "../../../../data/APIs";
import {
  getTableConfig,
  filterConfig,
  tableChipsFormatter,
  form_data,
} from "./subscriptionsTableConfig";
import constants from "../../../../constants/constants";
import { exportLargeListingData, is_class } from "../../../../utils/Utils";
import SessionStorageConstants from "constants/SessionStorage.constants";
// Components
import Alert from "../../../modules/Alert";
import ExlyTable from "common/Components/ExlyTable";
import {
  DetailsDrawerFooter,
  WhatsAppButton,
} from "webpage-leads/components/CustomColumns";
import { StatusField } from "../../schedule/BookedSession/components/TableFields/TableFields";
import { useNotifications } from "utils/hooks";
import { isSubscriptionPaymentType } from "utils/listing/listingUtils";
import { app_route_ids, app_route_keys } from "constants/urlPaths";
import { REPORT_TYPES } from "ui/pages/Reports/constants";
import {
  getTabsConfig,
  useIsRefundEnabled,
} from "../AllTransaction/utils/AllTransaction.util";
import { BOOKING_STATUS_MAP } from "constants/bookings.constants";
import { getSubdomain } from "features/Account/utils/getCreatorPageDetails";
import { TRANSACTIONS_LEARN_MORE_HREFS } from "features/Transactions/modules/GSTDetailsField/constants/Transactions.constants";
import { EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS } from "features/Common/modules/ExlyEmptyState/constants/ExlyEmptyState.constants";
import LearnMoreCta from "features/Common/modules/LearnMore/modules/LearnMoreCta/LearnMoreCta";
import { useIsDesktop } from "@my-scoot/exly-react-component-lib";
import { EXLY_TABLE_EMPTY_STATES_VERSIONS } from "common/Components/ExlyTable/ExlyTableEmptyState/constants/ExlyTableEmptyState.constants";
import useFeatureOnboardingChecker from "common/Components/FeatureOnboarding/useFeatureOnboardingChecker";
import { onboardingFeatureKeys } from "common/Components/FeatureOnboarding/FeatureOnboarding.constants";

// TODO: Empty State UI Pending
const FilteredEmptyState = () => {
  return (
    <div className="text-center py-5 text-muted">
      <div>No subscriptions found!</div>
    </div>
  );
};

const handleClick = (record) => {
  sessionStorage.setItem(
    SessionStorageConstants.IS_SUBSCRIPTION_PAYMENT_TYPE,
    isSubscriptionPaymentType(record)
  );
  sessionStorage.setItem(
    SessionStorageConstants.LISTING_CUSTOMER_ID,
    record.listing_uuid
  );
  sessionStorage.setItem(
    SessionStorageConstants.LISTING_CUSTOMER_TYPE,
    record.listing_type
  );
  const isNoSchedSub =
    record.listing_type === constants.scheduleTypesId.no_schedule &&
    record.course_end_magnitude > 0;
  const isWhatsappCommunity =
    record.listing_type === constants.scheduleTypesId.whatsapp;
  sessionStorage.setItem(
    SessionStorageConstants.LISTING_CUSTOMERS_IS_NO_SCHEDULE_SUBSCRIPTION,
    isNoSchedSub
  );

  window.location.href = `${window.location.origin}/#/${
    is_class({ type: record.listing_type }) ||
    isNoSchedSub ||
    isWhatsappCommunity
      ? app_route_ids[app_route_keys.subscription_customer]
      : app_route_ids[app_route_keys.listing_customer]
  }`;
};

// Main Component
const Subscriptions = (props) => {
  const isDesktop = useIsDesktop();
  const ref = useRef(null);
  const history = useHistory();
  const { pushNotification } = useNotifications();
  const [showAlert, setShowAlert] = useState(false);
  const [selectedRecordData] = useState("");
  const [staffMembers, setStaffMembers] = useState([]);
  const classes = useStyles();
  const { notify } = useNotifications();
  const showStaffFilter =
    orgPermissions.isOwner() ||
    orgPermissions.isMainUser() ||
    orgPermissions.isManager();

  const {
    isOnboardedPrevVal: isFeatureVisited,
    flagLoading: isFeatureFlagLoading,
    handleFeatureOnboardChange,
  } = useFeatureOnboardingChecker({
    featureKey: onboardingFeatureKeys.KNOWLEDGE_BASE.MANAGE_SUBSCRIPTIONS,
    successCallback: (marked) => !marked && handleFeatureOnboardChange(true),
  });

  const hideSubscriptionsPage = orgPermissions.isNavigationItemHidden(
    RESOURCE_KEYS.SUBSECTIONS.MANAGE_TRANSACTIONS.MANAGE_SUBSCRIPTION,
    RESOURCE_KEYS.SECTIONS.MANAGE_TRANSACTIONS
  );

  const showAllTransactionsPage = !orgPermissions.isNavigationItemHidden(
    RESOURCE_KEYS.SUBSECTIONS.MANAGE_TRANSACTIONS.ALL_TRANSACTIONS,
    RESOURCE_KEYS.SECTIONS.MANAGE_TRANSACTIONS
  );

  const isMyBookingsHidden = orgPermissions.isNavigationItemHidden(
    RESOURCE_KEYS.SECTIONS.MY_BOOKINGS
  );

  const isCustomerDetailsHidden = orgPermissions.isNavigationItemHidden(
    RESOURCE_KEYS.SECTIONS.ALL_CUSTOMERS
  );

  useEffect(() => {
    if (hideSubscriptionsPage) {
      history.push("/");
    }
    getOrgMembers();
    dataProvider.postAnalyticalEvent("creatorPage", "manageSubcription", {
      flow: "creatorPage",
      funnel: "creatorPage",
      ui_component: "Text Button",
      identifier: "Manage Subscriptions",
    });
  }, []);

  const getOrgMembers = async () => {
    try {
      const { data } = await dataProvider.custom_request(
        api.get_assignee_list,
        "GET",
        { page: "all" }
      );

      if (data && data.assignment_members) {
        const members = data.assignment_members.map((member) => ({
          id: member.org_uuid,
          name: member.member_name,
          label: member.member_name,
          value: member.org_uuid,
        }));
        setStaffMembers(members);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowAlert(false);
    }
  };

  const { isRefundEnabled } = useIsRefundEnabled();

  return (
    <div className="component-wrapper">
      <ExlyTable
        ra_props={{
          ...props,
        }}
        noExportButton={!orgPermissions.canExport()}
        tableTitle={
          <div className="w-100 d-flex align-items-center justify-content-between">
            {showAllTransactionsPage
              ? "Manage Transactions"
              : "My Subscriptions"}

            {!isDesktop && (
              <LearnMoreCta
                href={TRANSACTIONS_LEARN_MORE_HREFS.MANAGE_TRANSACTIONS}
              />
            )}
          </div>
        }
        tabConfig={
          showAllTransactionsPage &&
          getTabsConfig({ showRefundTab: isRefundEnabled })
        }
        selectedTab="manage-subscriptions"
        columnConfig={getTableConfig({
          handleRecordClick: handleClick,
          isMyBookingsHidden,
          isCustomerDetailsHidden,
        })}
        tableFilters={filterConfig({ staffMembers, showStaffFilter })}
        tableChipsFormatter={tableChipsFormatter(staffMembers)}
        layoutProps={{
          paddingDesktop: "0",
          paddingMobile: "0",
          paddingBottom: "68px",
          noMobileBoxShadow: true,
          showFixedBars: true,
          secondaryComponent: isDesktop && (
            <LearnMoreCta
              href={TRANSACTIONS_LEARN_MORE_HREFS.MANAGE_TRANSACTIONS}
            />
          ),
        }}
        customHandleExport={({ filterValues, isEmpty }) =>
          exportLargeListingData({
            filterValues,
            reportType: REPORT_TYPES.SUBSCRIPTIONS,
            notify,
            isEmpty,
          })
        }
        renderPrimaryColumnRightNode={(record) => {
          return (
            <>
              {is_empty(record.customer_phone) ||
              isCustomerDetailsHidden ? null : (
                <WhatsAppButton
                  record={record}
                  source="customer_phone"
                  countryCodeKey="country_code"
                />
              )}
            </>
          );
        }}
        filteredEmptyState={<FilteredEmptyState />}
        borderedFields
        fieldsLeftPadded
        drawerFieldsBordered
        fieldsAlignment="space-between"
        drawerFieldsAlignment="space-between"
        recordFooterVariant="secondary"
        getRecordFooterClassName={(record) =>
          BOOKING_STATUS_MAP[record.status]
            ? classes[`footer__${BOOKING_STATUS_MAP[record.status].title}`]
            : ""
        }
        customPrimaryAction={
          isMyBookingsHidden || isCustomerDetailsHidden
            ? { renderCustomAction: () => Fragment }
            : {
                label: "See Details",
                onClick: (record) => handleClick(record),
              }
        }
        renderDrawerFooter={(record) => (
          <DetailsDrawerFooter
            record={record}
            source="customer_phone"
            countryCodeKey="country_code"
          />
        )}
        renderSecondaryAction={(record) => <StatusField record={record} />}
        emptyStateProps={{
          isLoading: isFeatureFlagLoading,
          version: EXLY_TABLE_EMPTY_STATES_VERSIONS.V2,
          title: "No subscriptions yet",
          description:
            "As soon as we have new subscription, it will show up here. View the video to learn more.",
          videoSrc: TRANSACTIONS_LEARN_MORE_HREFS.MANAGE_SUBSCRIPTION_VIDEO,
          playerProps: { playing: !isFeatureFlagLoading && !isFeatureVisited }, // autoplay the knowledge base video on the first visit to this feature
          primaryCtaProps: {
            title: "Add a session",
            onClick: () => {
              window.sessionStorage.setItem(
                "fromRoute",
                "Manage Subscriptions"
              );
              window.location.href = !is_empty(getSubdomain())
                ? `${window.location.origin}/#/ScheduleList`
                : `${window.location.origin}/#/TemplatePreview`;
            },
          },
          secondaryCtaProps: {
            variant: EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS.learn_more,
            learnMoreHref: TRANSACTIONS_LEARN_MORE_HREFS.MANAGE_TRANSACTIONS,
          },
          className: "mt-4",
        }}
      />
      <Modal open={showAlert} onClick={handleClickOutside}>
        <Alert
          primary_cta_text={form_data.primary_cta_text}
          secondary_cta_text={form_data.secondary_action}
          body={`You are about to cancel a subscription of ${getUserCurrencySymbol()} ${
            selectedRecordData.creator_sales
          } by ${selectedRecordData.customer_name} for ${
            selectedRecordData.session_name
          }`}
          header={"Confirm Action"}
          primary_action={async () => {
            if (is_empty(selectedRecordData) || is_empty(selectedRecordData.id))
              return;
            try {
              dataProvider.postAnalyticalEvent(
                "activeSubcription",
                "confirmSubcriptionP",
                {
                  flow: "manageTransactions",
                  funnel: "manageTransactions",
                  ui_component: "Button",
                  identifier: form_data.primary_cta_text,
                  booking_meta: { uuid: selectedRecordData.id },
                }
              );
              const status = await dataProvider.custom_request(
                api.cancel_subscription,
                "POST",
                { subscription_id: `${selectedRecordData.id}` }
              );
              pushNotification(status.message, {
                variant: "outlined",
                color: "primary",
              });
              setShowAlert(false);
              if (status.status === 200) {
                window.location.reload();
              }
            } catch (err) {
              pushNotification(err.body.message, {
                variant: "outlined",
                color: "coral_red",
              });
            }
          }}
          secondary_action={() => {
            dataProvider.postAnalyticalEvent(
              "activeSubcription",
              "cancelSubcriptionP",
              {
                flow: "manageTransactions",
                funnel: "manageTransactions",
                ui_component: "Button",
                identifier: form_data.secondary_action,
                booking_meta: { uuid: selectedRecordData.id },
              }
            );
            setShowAlert(false);
          }}
        />
      </Modal>
    </div>
  );
};

export default Subscriptions;
