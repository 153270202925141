import makeStyles from "@material-ui/styles/makeStyles";

export default makeStyles((theme) => ({
  discount_wrapper: {
    color: "#6c5dd3",
  },
  pointer_cursor: {
    cursor: "pointer",
  },
  status: {
    color: theme?.palette?.basic?.white,
    float: "right",
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 400,
    display: "flex",
    alignItems: "center",
    gap: "4px",
    borderRadius: "5px",
    padding: "0 8px",
    "& .processed_icon": {
      width: "18px",
      height: "18.65px",
    },
    "& .failed_icon": {
      width: "21.04px",
      height: "21.34px",
    },
  },
  status_added_booking: {
    backgroundColor: theme?.palette?.fine_pine?.main,
  },
  status_processed: {
    backgroundColor: theme?.palette?.fine_pine?.main,
  },
  status_failed: {
    backgroundColor: theme?.palette?.basic?.danger_red,
  },
  status_pending: {
    backgroundColor: theme?.palette?.butterscotch_yellow?.main,
  },
  status_refunded: {
    backgroundColor: theme?.palette?.fine_pine?.main,
  },
  status_refund_initiated: {
    backgroundColor: theme?.palette?.fine_pine?.main,
  },
}));
