import React from "react";
import ExlyModal from "common/Components/ExlyModal";
import styles from "./InitiateRefund.module.css";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import RefundModalFooter from "./RefundModalFooter";
import { InitiateRefund } from "features/Crm/modules/Transactions/modules/Refund/modules/InitiateRefund";
import { REFUND_PAGES } from "features/Crm/modules/Transactions/modules/Refund/Refund.constants";
import { ReviewSelection } from "features/Crm/modules/Transactions/modules/Refund/modules/ReviewSelection";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { InitiateRefundModalContext } from "../../AllTransaction";
import { is_empty } from "utils/validations";

// TODO: move this folder to features/refund

const InitiateRefundModal = () => {
  const {
    isOpenInitiateRefundModal,
    handleRefundModalClose,
    setActiveRefundPage,
    activeRefundPage,
    selectedRefundRecords,
  } = React.useContext(InitiateRefundModalContext);

  const getInitiateRefundModalTitle = () => {
    if (is_empty(selectedRefundRecords)) return;
    if (activeRefundPage === REFUND_PAGES.reviewSelection) {
      return `Review Selection (${Object.keys(selectedRefundRecords).length})`;
    } else
      return (
        <div
          onClick={() => setActiveRefundPage(REFUND_PAGES.reviewSelection)}
          className={`d-flex align-items-center pointer`}
        >
          <KeyboardBackspaceIcon className={`mr-2`} />
          Initiate Refund
        </div>
      );
  };

  return (
    <ExlyModal
      open={isOpenInitiateRefundModal.refundModal}
      onClose={handleRefundModalClose}
      modal_props={{
        modalPaperClassName:
          activeRefundPage === REFUND_PAGES.reviewSelection
            ? styles.reviewSelectionContainer
            : styles.modalContainer,
      }}
      title={getInitiateRefundModalTitle()}
      customFooter={<RefundModalFooter />}
    >
      <div className="my-2">
        {activeRefundPage === REFUND_PAGES.reviewSelection ? (
          <ReviewSelection />
        ) : (
          <InitiateRefund />
        )}
      </div>
    </ExlyModal>
  );
};

export default withComponentLibraryTheme(InitiateRefundModal);
