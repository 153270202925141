// Third party imports
import React from "react";
import classnames from "classnames";

// Styles and icons
import useStyles from "./TableFields.styles";
import { RESOURCE_KEYS, orgPermissions } from "utils/OrgPermissions";
import { BOOKING_STATUS_MAP } from "constants/bookings.constants";

export const StatusField = ({ record = {}, source }) => {
  const classes = useStyles();
  const { title, variant, icon } = BOOKING_STATUS_MAP[record[source]] ?? {};

  return (
    <span
      className={classnames(
        classes.status,
        variant && classes[`status__${variant}`],
        classes.floatRight
      )}
    >
      {icon}
      {title}
    </span>
  );
};

export const CustomersField = ({ record = {}, source, onClick }) => {
  const classes = useStyles();
  const isCustomerDetailsHidden = orgPermissions.isNavigationItemHidden(
    RESOURCE_KEYS.SECTIONS.ALL_CUSTOMERS
  );
  const customerCount = `${record[source]} participants`;

  if (!record[source]) return <p>N/A</p>;
  if (isCustomerDetailsHidden) return <p>{customerCount}</p>;

  return (
    <p className={classes.listingLink} onClick={() => onClick(record)}>
      {customerCount}
    </p>
  );
};
