import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  footer__Active: {
    background: `${theme?.palette?.fine_pine?.shade_50} !important`,
  },
  footer__Pending: {
    background: `${theme?.palette?.butterscotch_yellow?.shade_50} !important`,
  },
  footer__Halted: {
    background: `${theme?.palette?.coral_red?.shade_50} !important`,
  },
  footer__Cancelled: {
    background: `${theme?.palette?.coral_red?.shade_50} !important`,
  },
  footer__Completed: {
    background: `${theme?.palette?.fine_pine?.shade_50} !important`,
  },
}))