import { put, takeEvery, call, select } from "redux-saga/effects";
import dataProvider from "./../../../../data/dataProvider";
import api from "./../../../../data/APIs";
import {
  EXLY_CREATEPAYMENTLINKS__REQUEST_UPCOMMING_OFFERINGS,
  EXLY_CREATEPAYMENTLINKS__REQUEST_UPCOMMING_OFFERINGS_FILTER,
  EXLY_CREATEPAYMENTLINKS__SET_UPCOMMING_OFFERINGS,
  EXLY_CREATEPAYMENTLINKS__SHOW_UPCOMMING_OFFERINGS_LOADING,
  EXLY_CREATEPAYMENTLINKS__RESET_UPCOMMING_OFFERINGS,
  EXLY_SCHEDULELIST__REQUEST_MEMBERS,
  EXLY_SCHEDULELIST__SET_MEMBERS,
} from "./actions";

/**
 * Worker Saga: to get Upcomming offerings data
 */
function* getUpcommingOfferings({ payload }) {
  const { force_update } = payload;

  const state = yield select((state) => state.transactions.upcommingOfferings);
  try {
    if (force_update || (!state.isLoading && !state.isFetched)) {
      yield put({
        type: EXLY_CREATEPAYMENTLINKS__SHOW_UPCOMMING_OFFERINGS_LOADING,
      });
      yield put({ type: EXLY_CREATEPAYMENTLINKS__RESET_UPCOMMING_OFFERINGS });
      const response = yield call(
        dataProvider.custom_request,
        api.get_host_listings_upcomming
      );
      if (response.status === 200) {
        yield put({
          type: EXLY_CREATEPAYMENTLINKS__SET_UPCOMMING_OFFERINGS,
          payload: {
            isLoading: false,
            data: [...response.data.listing],
            error: {},
            isFetched: true,
            isFiltering: false,
          },
        });
      } else {
        throw `API: ${api.get_host_listings_upcomming + response.status} FAIL`;
      }
    }
  } catch (error) {
    yield put({
      type: EXLY_CREATEPAYMENTLINKS__RESET_UPCOMMING_OFFERINGS,
      payload: { error: error },
    });
  }
}

/**
 * Worker Saga: to get LIVE offerings with Type Filter
 */
function* getUpcommingFilteredOfferings({ payload }) {
  let state = yield select((state) => state.transactions.upcommingOfferings);
  try {
    if (!state.isLoading && payload) {
      yield put({
        type: EXLY_CREATEPAYMENTLINKS__RESET_UPCOMMING_OFFERINGS,
        payload: { error: {} },
      });

      let _api = api.get_host_listings_upcomming;

      const response = yield call(dataProvider.custom_request, _api, "GET", {
        org_uuid: payload.org_uuid,
        sku_type: payload.sku_type,
      });
      if (response.status === 200) {
        yield put({
          type: EXLY_CREATEPAYMENTLINKS__SET_UPCOMMING_OFFERINGS,
          payload: {
            isLoading: false,
            data: [...response.data.listing],
            error: {},
            isFiltering: true,
          },
        });
      } else {
        throw `API: ${api.get_host_listings_upcomming + response.status} FAIL`;
      }
    }
  } catch (error) {
    yield put({
      type: EXLY_CREATEPAYMENTLINKS__RESET_UPCOMMING_OFFERINGS,
      payload: { error: error },
    });
  }
}

/**
 * Worker Saga: to get members data
 */
function* getMembers() {
  const state = yield select((state) => state.transactions.members);
  try {
    yield put({
      type: EXLY_SCHEDULELIST__SET_MEMBERS,
      payload: { data: [], error: {} },
    });
    const response = yield call(
      dataProvider.custom_request,
      api.get_assignee_list,
      "GET",
      { page: "all" }
    );

    if (response.status === 200) {
      yield put({
        type: EXLY_SCHEDULELIST__SET_MEMBERS,
        payload: {
          data: response.data.assignment_members,
          error: {},
        },
      });
    } else {
      throw `API: ${api.get_assignee_list} FAIL`;
    }
  } catch (error) {
    yield put({
      type: EXLY_SCHEDULELIST__SET_MEMBERS,
      payload: {
        data: [...state?.data],
        error: error,
      },
    });
  }
}

/**
 * Watcher Saga: UpcommingOfferings
 */
function* transactionSaga() {
  yield takeEvery(
    EXLY_CREATEPAYMENTLINKS__REQUEST_UPCOMMING_OFFERINGS,
    getUpcommingOfferings
  );
  yield takeEvery(
    EXLY_CREATEPAYMENTLINKS__REQUEST_UPCOMMING_OFFERINGS_FILTER,
    getUpcommingFilteredOfferings
  );
  yield takeEvery(EXLY_SCHEDULELIST__REQUEST_MEMBERS, getMembers);
}

export default transactionSaga;
