// Third party imports
import React from "react";
import classnames from "classnames";

// Styles and icons
import useStyles from "../../Subscriptions/components/TableFields/TableFields.styles";
import { Button } from "@my-scoot/component-library-legacy";
import {
  HEADER_NOTE,
  REFUND_STATUS,
  REFUND_STATUS_ENUM,
} from "../refund.constant";
import { dataProvider } from "data";
import { ActionField } from "common/Components/TableFields/TableFields";
import { notification_color_keys, useNotifications } from "utils/hooks";
import { useRefresh } from "react-admin";
import { isRequestSuccessful } from "utils/Utils";
import constants from "constants/constants";
import { TRANSACTION_APIS } from "../../transactions.apis";
import { apiMethods } from "data/api.constants";
import { Tooltip } from "@my-scoot/component-library-legacy";

export const StatusField = ({ record = {}, source }) => {
  const classes = useStyles();
  const { title, variant, icon } =
    REFUND_STATUS[REFUND_STATUS_ENUM[record[source]]] ?? {};
  const statusChip = (
    <span
      className={classnames(
        classes.status,
        variant && classes[`status__${variant}`]
      )}
    >
      {icon}
      {title}
    </span>
  );
  // checking status is pending/initiated and show tooltip only for this
  const checkStatusType = [
    REFUND_STATUS.PENDING.id,
    REFUND_STATUS.EXECUTED.id,
  ].includes(record[source]);
  if (checkStatusType) {
    return (
      <Tooltip title={HEADER_NOTE.refund_status} color="primary" arrow>
        {statusChip}
      </Tooltip>
    );
  }

  return statusChip;
};

export const RefundActionField = ({ record }) => {
  const { notify } = useNotifications();
  const refresh = useRefresh();
  if (REFUND_STATUS.FAILED.id !== record.status) return null;
  const handleRetryOnClick = async (record) => {
    try {
      const response = await dataProvider.custom_request(
        `${TRANSACTION_APIS.refund_history_retry}`,
        apiMethods.POST,
        {
          order_uuid: record.order_uuid,
        }
      );
      if (isRequestSuccessful(response.status)) {
        notify(response.message, notification_color_keys.success);
        refresh();
      }
    } catch (err) {
      notify(
        err?.body?.message ?? constants.error_message,
        notification_color_keys.error
      );
    }
  };
  const tableButton = (label) => (
    <Button color="primary" size="thin">
      {label}
    </Button>
  );
  return (
    <ActionField
      record={record}
      label={tableButton("Retry")}
      onClick={() => handleRetryOnClick(record)}
    />
  );
};
