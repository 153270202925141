import { refund_tab_config, tab_config } from "../AllTransactionTableConfig";
import { is_empty } from "utils/validations";
import { TRANSACTIONS_REFUND_OPTIONS } from "../transactions.constants";
import useFeaturesActivationStatus from "features/FeatureActivationStatus/utils/useFeaturesActivationStatus";
import { feature_activation_keys } from "features/FeatureActivationStatus/FeatureActivationStatus.constants";

/**
 * This function checks whether to show refunds tab and based
 * on that returns the config
 * @returns new table config
 */
export const getTabsConfig = ({ showRefundTab }) => {
  return [...tab_config, showRefundTab && refund_tab_config];
};

export const useIsRefundEnabled = () => {
  const { loading, featuresActivationStatus } = useFeaturesActivationStatus();
  const isRefundEnabled =
    !loading &&
    featuresActivationStatus[feature_activation_keys.is_refunds_enabled.key];
  return { isRefundEnabled };
};

export const isCustomRefundAmountType = (refundType) =>
  refundType === TRANSACTIONS_REFUND_OPTIONS.REFUND_CUSTOM_AMOUNT;

export const validateRefundAmount = (refundAmount, transactionAmount) => {
  if (is_empty(refundAmount)) {
    return "Required";
  } else if (refundAmount < 1 || refundAmount > transactionAmount) {
    return "Please enter a refund amount greater than 0 and less than or equal to the transaction amount";
  }
  return null;
};
