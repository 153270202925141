import { EXLY_TABLE_EMPTY_STATES_VERSIONS } from "common/Components/ExlyTable/ExlyTableEmptyState/constants/ExlyTableEmptyState.constants";
import SessionStorageConstants from "constants/SessionStorage.constants";
import { getSubdomain } from "features/Account/utils/getCreatorPageDetails";
import { EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS } from "features/Common/modules/ExlyEmptyState/constants/ExlyEmptyState.constants";
import { TRANSACTIONS_LEARN_MORE_HREFS } from "features/Transactions/modules/GSTDetailsField/constants/Transactions.constants";
import { is_empty } from "utils/validations";

export const TRANSACTIONS_REFUND_OPTIONS = {
  REFUND_LISTING_PRICE: 1,
  CHARGE_IHF_TO_CUSTOMERS: 2,
  REFUND_CUSTOM_AMOUNT: 3,
};

export const STATUS = {
  1: "PENDING",
  2: "RECEIVED",
  3: "TRANSFERRED",
  4: "REFUNDED",
  5: "CANCELLED",
  6: "AUTHORIZED",
  7: "PROCESSING",
  8: "FAILED",
  9: "SUBSCRIPTION_CREATED",
};

export const TRANS_CONSTANTS = {
  place_after_decimal: 2,
  free: "free",
  added_booking: "Added Booking",
  refund_initiated: "Refund Initiated",
  refund: "REFUNDED",
  cancelled: "CANCELLED",
  order: "order",
  initiate_refund: "Initiate Refund",
  all_transaction: "all_transaction",
  receipt_url: "receipt_url",
};

export const POSTFIX_RETURN_URL = "payments/exly/transactions/list";

export const emptyStateProps = {
  version: EXLY_TABLE_EMPTY_STATES_VERSIONS.V2,
  title: "No transactions yet",
  description:
    "As soon as we have a new transaction, it will show up here. View the video to learn more.",
  videoSrc: TRANSACTIONS_LEARN_MORE_HREFS.ALL_TRANSACTIONS_VIDEO,
  secondaryCtaProps: {
    variant: EXLY_EMPTY_STATE_SECONDARY_CTA_VARIANTS.learn_more,
    learnMoreHref: TRANSACTIONS_LEARN_MORE_HREFS.MANAGE_TRANSACTIONS,
  },
  primaryCtaProps: {
    title: "Add a session",
    onClick: () => {
      window.sessionStorage.setItem(
        SessionStorageConstants.FROM_ROUTE,
        "All Transactions"
      );
      window.location.href = !is_empty(getSubdomain())
        ? `${window.location.origin}/#/ScheduleList`
        : `${window.location.origin}/#/TemplatePreview`;
    },
  },
  className: "mt-4",
};
