// Third party imports

// Utils and providers
import constants from "constants/constants";
import { api } from "data";

// Components
import {
  formatEndDate,
  formatGatewayType,
  formatGuestEmail,
  formatGuestName,
  formatGuestPhoneNumber,
  formatListingTitle,
  formatListingType,
  formatMaxPrice,
  formatMinPrice,
  formatOfferingVariation,
  formatOrgUuid,
  formatPaymentDebitMode,
  formatPaymentType,
  formatStartDate,
  formatTransactionId,
} from "features/Crm/modules/Transactions/utils/Transactions.utils";
import {
  getEndDateFilterConfig,
  getGatewayTypeFilterConfig,
  getGuestEmailFilterConfig,
  getGuestNameFilterConfig,
  getGuestPhoneNumberFilterConfig,
  getListingTitleFilterConfig,
  getListingTypeFilterConfig,
  getMaxPriceFilterConfig,
  getMinPriceFilterConfig,
  getOfferingVariationFilterConfig,
  getOrgUuidFilterConfig,
  getPaymentDebitModeFilterConfig,
  getPaymentTypeFilterConfig,
  getStartDateFilterConfig,
  getTransactionAmountConfig,
  getTransactionBillingStateConfig,
  getTransactionCustomerEmailConfig,
  getTransactionCustomerInvoiceConfig,
  getTransactionCustomerNameConfig,
  getTransactionCustomerPhoneConfig,
  getTransactionDateConfig,
  getTransactionDebitTypeConfig,
  getTransactionDiscountAmountConfig,
  getTransactionDiscountCodeConfig,
  getTransactionGSTInfoConfig,
  getTransactionIdFilterConfig,
  getTransactionInternationalConfig,
  getTransactionLimitedTimeOfferConfig,
  getTransactionOfferingNameConfig,
  getTransactionOfferingOwnerConfig,
  getTransactionOfferingTypeConfig,
  getTransactionPaymentAmountConfig,
  getTransactionPaymentModeConfig,
  getTransactionPaymentTypeConfig,
  getTransactionPayoutDateConfig,
  getTransactionPayoutStatusConfig,
  getTransactionPricePerSlotConfig,
  getTransactionTransactionIdConfig,
  getTransactionTypeConfig,
  getTransactionUnitsConfig,
  getTransactionUTRInfoConfig,
} from "features/Crm/modules/Transactions/utils/Transactions.tableConfigs";
import {
  ORDER_PAYMENT_TYPE,
  ORDER_PAYMENT_TYPE_LABELS,
} from "schedule-v2/constants";

export const form_data = {
  name: "Name",
  email: "Email",
  start_date: "From",
  end_date: "To",
  session_name: "Offering Name",
  offeringVariation: {
    label: "Variation",
    filterKey: "offering_variant_title",
  },
  phone: "Phone",
  min_price: "Minimum Price",
  max_price: "Maximum Price",
  type: "Offering Type",
  payment_plan_type: "Payment Type",
  clear: "Clear Filter",
  staff: "Staff",
  payment_debit_mode: "Debit Type",
};

export const field_data = {
  session_name: "session_name",
  payment_debit_mode: "payment_debit_mode",
  payment_type: "payment_type",
  transaction_id: "transaction_id",
  gateway_type: "gateway_type",
  payment_mode: "payment_mode",
  billing_state: "billing_state",
  customer_gst_info: "customer_gst_info",
  gst_name: "gst_name",
  gst_number: "gst_number",
};

export const FILTERS_OPTIONS_PLACEHOLDERS = {
  [field_data.transaction_id]: "Transaction ID",
  [field_data.gateway_type]: "Booking type",
  [field_data.payment_mode]: "Mode of payment",
  [field_data.customer_gst_info]: "GST details",
  [field_data.billing_state]: "State",
};

export const getTableConfig = (
  hidePricePerSlot,
  hideTransactionAmount,
  onClickOffer,
  hideNumberOfUnits,
  hidePaymountAmount,
  hidePayoutStatus,
  hidePayoutDate,
  isCustomerDetailsHidden,
  isOfferingAutoDebitEnabled
) => [
  getTransactionCustomerNameConfig(),
  getTransactionCustomerPhoneConfig({ isCustomerDetailsHidden }),
  getTransactionCustomerEmailConfig({ isCustomerDetailsHidden }),
  getTransactionOfferingOwnerConfig(),
  getTransactionOfferingNameConfig(),
  getTransactionOfferingTypeConfig(),
  getTransactionPaymentTypeConfig(),
  ...(isOfferingAutoDebitEnabled ? [getTransactionDebitTypeConfig()] : []),
  getTransactionPricePerSlotConfig({ hidePricePerSlot }),
  getTransactionAmountConfig({ hideTransactionAmount }),
  getTransactionDiscountCodeConfig({ onClickOffer }),
  getTransactionLimitedTimeOfferConfig({ onClickOffer }),
  getTransactionDiscountAmountConfig(),
  getTransactionPaymentModeConfig(),
  getTransactionTransactionIdConfig(),
  getTransactionDateConfig(),
  getTransactionUnitsConfig({ hideNumberOfUnits }),
  getTransactionInternationalConfig(),
  getTransactionPaymentAmountConfig({ hidePaymountAmount }),
  getTransactionPayoutDateConfig({ hidePayoutDate }),
  getTransactionGSTInfoConfig(),
  getTransactionBillingStateConfig(),
  getTransactionCustomerInvoiceConfig({
    hideNumberOfUnits,
    isCustomerDetailsHidden,
  }),
  getTransactionUTRInfoConfig(),
  getTransactionTypeConfig(),
  getTransactionPayoutStatusConfig({ hidePayoutStatus }),
];

export const listingTypeOptions = constants.schedule_type.map((item) => ({
  ...item,
  label: item.name,
  value: item.id,
}));

export const INSTALMENTS_FILTER_VALUE = `${ORDER_PAYMENT_TYPE.INITIAL_PART_PAYMENT},${ORDER_PAYMENT_TYPE.PART_PAYMENT_INSTALLMENTS}`;

export const PAYMENT_PLAN_TYPE_OPTIONS = [
  {
    label: ORDER_PAYMENT_TYPE_LABELS[ORDER_PAYMENT_TYPE.ONE_TIME_PAYMENT],
    value: ORDER_PAYMENT_TYPE.ONE_TIME_PAYMENT,
  },
  {
    label: ORDER_PAYMENT_TYPE_LABELS[ORDER_PAYMENT_TYPE.INITIAL_PART_PAYMENT],
    value: INSTALMENTS_FILTER_VALUE,
  },
  {
    label: ORDER_PAYMENT_TYPE_LABELS[ORDER_PAYMENT_TYPE.SUBSCRIPTION],
    value: ORDER_PAYMENT_TYPE.SUBSCRIPTION,
  },
];

export const filterConfig = (
  staffMembers,
  showStaffMembers,
  isOfferingAutoDebitEnabled
) => [
  getGuestNameFilterConfig(),
  getGuestEmailFilterConfig(),
  getListingTitleFilterConfig(),
  getGuestPhoneNumberFilterConfig(),
  getOfferingVariationFilterConfig(),
  getMinPriceFilterConfig(),
  getMaxPriceFilterConfig(),
  getListingTypeFilterConfig(),
  getPaymentTypeFilterConfig(),
  ...(isOfferingAutoDebitEnabled ? [getPaymentDebitModeFilterConfig()] : []),
  getOrgUuidFilterConfig({ staffMembers, showStaffMembers }),
  getStartDateFilterConfig(),
  getEndDateFilterConfig(),
  getTransactionIdFilterConfig(),
  getGatewayTypeFilterConfig(),
];

export const ORDER_PAYMENT_TYPE_FILTER_CHIPS_LABELS = {
  [ORDER_PAYMENT_TYPE.ONE_TIME_PAYMENT]: "One-time payment",
  [INSTALMENTS_FILTER_VALUE]: "Instalments",
  [ORDER_PAYMENT_TYPE.SUBSCRIPTION]: "Subscription",
};

const ALL_TRANSACTIONS_FILTER_KEYS = {
  guest_name: "guest_name",
  guest__email__icontains: "guest__email__icontains",
  listing__title__icontains: "listing__title__icontains",
  offering_variant_title: "offering_variant_title",
  guest__phone_number__contains: "guest__phone_number__contains",
  min_price: "min_price",
  max_price: "max_price",
  listing__type: "listing__type",
  payment_type: "payment_type",
  payment_debit_mode: "payment_debit_mode",
  org_uuid: "org_uuid",
  created_at__start_range: "created_at__start_range",
  created_at__end_range: "created_at__end_range",
  transaction_id: "transaction_id",
  gateway_type: "gateway_type",
};

export const ALL_TRANSACTIONS_TABLE_FILTER_CHIPS_FORMATTERS = (
  staffMembers
) => ({
  [ALL_TRANSACTIONS_FILTER_KEYS.guest_name]: formatGuestName,
  [ALL_TRANSACTIONS_FILTER_KEYS.guest__email__icontains]: formatGuestEmail,
  [ALL_TRANSACTIONS_FILTER_KEYS.listing__title__icontains]: formatListingTitle,
  [ALL_TRANSACTIONS_FILTER_KEYS.offering_variant_title]:
    formatOfferingVariation,
  [ALL_TRANSACTIONS_FILTER_KEYS.guest__phone_number__contains]:
    formatGuestPhoneNumber,
  [ALL_TRANSACTIONS_FILTER_KEYS.min_price]: formatMinPrice,
  [ALL_TRANSACTIONS_FILTER_KEYS.max_price]: formatMaxPrice,
  [ALL_TRANSACTIONS_FILTER_KEYS.listing__type]: formatListingType,
  [ALL_TRANSACTIONS_FILTER_KEYS.payment_type]: formatPaymentType,
  [ALL_TRANSACTIONS_FILTER_KEYS.payment_debit_mode]: formatPaymentDebitMode,
  [ALL_TRANSACTIONS_FILTER_KEYS.org_uuid]: formatOrgUuid(staffMembers),
  [ALL_TRANSACTIONS_FILTER_KEYS.created_at__start_range]: formatStartDate,
  [ALL_TRANSACTIONS_FILTER_KEYS.created_at__end_range]: formatEndDate,
  [ALL_TRANSACTIONS_FILTER_KEYS.transaction_id]: formatTransactionId,
  [ALL_TRANSACTIONS_FILTER_KEYS.gateway_type]: formatGatewayType,
});

export const tableChipsFormatter = (staffMembers) => (filter, value) => {
  const formatter =
    ALL_TRANSACTIONS_TABLE_FILTER_CHIPS_FORMATTERS(staffMembers)[filter];
  return formatter ? formatter(value) : undefined;
};

// @dev - Do not use tabsConfig directly, as you may not
// include refund_tab_config in it. Instead, use the util
// getTabsConfig - src/ui/pages/transactions/AllTransaction/utils/AllTransaction.util.js
export const tab_config = [
  {
    label: "All Transactions",
    value: "all-transactions",
    path: `/${api.transaction_list}`,
  },
  {
    label: "Manage Subscriptions",
    value: "manage-subscriptions",
    path: "/payments/exly/subscription/list",
  },
];

export const refund_tab_config = {
  label: "Refund History",
  value: "refund-history",
  path: `/${api.refund_history}`,
};
