/* eslint-disable react/prop-types */
import React from "react";
import classnames from "classnames";
import useStyles from "./TableFields.styles";
import LaunchIcon from "@material-ui/icons/Launch";
import Inbox from "@material-ui/icons/Inbox";
import ReportProblemRoundedIcon from "@material-ui/icons/ReportProblemRounded";
import { ReactComponent as ShoppingBagIcon } from "assets/vectors/shopping_bag.svg";
import AutorenewRoundedIcon from "@material-ui/icons/AutorenewRounded";
import { ReactComponent as PendingClockIcon } from "assets/vectors/pending_clock.svg";

import { is_empty } from "utils/validations";

import Tooltip from "../././../../../../modules/Tooltip";

export const OfferField = ({ record = {}, source, onClick }) => {
  const classes = useStyles();
  const offer = record[source];
  return (
    <div className={classes.discount_wrapper}>
      {offer ? (
        <div
          className={classes.pointer_cursor}
          onClick={() => onClick(record, source)}
        >
          <div>{offer}</div>
          <LaunchIcon />
        </div>
      ) : (
        "N/A"
      )}
    </div>
  );
};

export const PAYOUT_STATUS = {
  "Added Booking": {
    name: "added_booking",
    icon: <ShoppingBagIcon />,
  },
  Processed: {
    name: "processed",
    icon: (
      <AutorenewRoundedIcon
        preserveAspectRatio="none"
        className="processed_icon"
      />
    ),
  },
  Failed: {
    name: "failed",
    icon: (
      <ReportProblemRoundedIcon
        preserveAspectRatio="none"
        className="failed_icon"
      />
    ),
  },
  Pending: {
    name: "pending",
    icon: <PendingClockIcon />,
  },
  Refunded: {
    name: "refunded",
    icon: (
      <AutorenewRoundedIcon
        preserveAspectRatio="none"
        className="processed_icon"
      />
    ),
  },
  "Refund Initiated": {
    name: "refund_initiated",
    icon: (
      <AutorenewRoundedIcon
        preserveAspectRatio="none"
        className="processed_icon"
      />
    ),
  },
};

export const StatusField = ({ record = {}, source }) => {
  const classes = useStyles();
  if (is_empty(record[source])) return <>N/A</>;
  const { name, icon } = PAYOUT_STATUS[record[source]] || {};
  return (
    <div
      className={classnames(classes.status, name && classes[`status_${name}`])}
    >
      {icon}
      <span>{record[source]}</span>
    </div>
  );
};

// TODO: tooltip not working in header of dektop table
export const PayoutFieldHeader = () => (
  <div style={{ minWidth: 111 }}>
    {"Payout Date"}
    <Tooltip
      text={
        "Your payout will be processed by this date from our end; however, it may take up to 48hrs. for the amount to get settled into your bank account"
      }
    />
  </div>
);

// TODO: Empty State UI Pending
export const FilteredEmptyState = () => {
  return (
    <div className="text-center py-5 text-muted">
      <Inbox style={{ fontSize: 60 }} />
      <div>No transactions found!</div>
    </div>
  );
};
export const OfferingPlanNameField = ({ record = {} }) => {
  return (
    <>
      <div>
        {record.session_name || record.listing_name}
        {record.offering_variant_uuid
          ? ` - ${record.offering_variant_title}`
          : ""}
      </div>
      {record.purchased_plan_name ? (
        <div>
          <span style={{ color: "#0052cc" }}>Plan: </span>
          <span>{record.purchased_plan_name}</span>
        </div>
      ) : null}
    </>
  );
};
