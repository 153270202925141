import React from "react";
import style from "../../Style.module.css";
import StatsCard from "common/Components/StatsCard";
import BarChart from "@material-ui/icons/BarChart";
import { ReactComponent as AutoGraph } from "assets/vectors/auto_graph.svg";
import { ReactComponent as AutoAwesome } from "assets/vectors/auto_awesome.svg";
import { FAQS } from "../data";
import { Faqcard } from "./FaqCard";
import { is_empty } from "utils/validations";
import { api, dataProvider } from "data";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";

const PerformanceView = () => {
  const [stats_data, set_stats_data] = React.useState(null);

  React.useEffect(() => {
    (async () => {
      try {
        const status = await dataProvider.custom_request(
          api.dashboard.stats,
          "GET",
          { success_rate: true }
        );
        if (status.status === 200) {
          set_stats_data(status?.data);
        }
      } catch (err) {
        console.log("err", err);
      }
    })();
  }, []);

  return (
    <div className="component-wrapper">
      {!is_empty(stats_data) &&
      stats_data?.success_rate > stats_data?.market_success_rate ? (
        <>
          <div className={`mb-2 ${style.fw_bolder}`}>Quick Stats</div>
          <div className={`row align-items-center`}>
            <div className={`col-12 col-md-4`}>
              <StatsCard
                showLinkText={false}
                className={style.stats_card}
                color="primary"
                title={"MARKET SUCCESS RATE (AVG.)"}
                statsNumber={`${stats_data?.market_success_rate}%`}
                Icon={BarChart}
              />
            </div>
            <div className={`col-12 col-md-3`}>
              <StatsCard
                showLinkText={false}
                className={style.stats_card}
                color="fine_pine"
                title={"YOUR SUCCESS RATE"}
                statsNumber={`${stats_data?.success_rate}%`}
                Icon={AutoGraph}
              />
            </div>

            <div
              className={`${style.light_grey_text} ml-2 f-2 d-flex col-md-4 col-12`}
            >
              <AutoAwesome />
              &nbsp;{" "}
              <span>
                Great! Your user transactions are smooth sailing. Keep your
                worries at bay!
              </span>
            </div>
          </div>
        </>
      ) : (
        <div>
          <img
            src={require("assets/vectors/performance_view_empty.png")}
            alt="performance_view_empty"
          />
          <div className={`${style.light_grey_text} mt-4 mb-4`}>
            Constantly working to make you happy. And, while we do that, you may
            read below, a curated guide on transactions.
          </div>
        </div>
      )}

      <div className={`mt-4 ${style.fw_bolder}`}>Exly Help Guide</div>

      <div className={`row justify-content-between`}>
        {FAQS.map((item) => {
          return (
            <div className={`col-md-4 col-12`} key={item.id}>
              <Faqcard faqItem={item} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default withComponentLibraryTheme(PerformanceView);
