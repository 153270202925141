import {
  EXLY_CREATEPAYMENTLINKS__SET_UPCOMMING_OFFERINGS,
  EXLY_CREATEPAYMENTLINKS__SHOW_UPCOMMING_OFFERINGS_LOADING,
  EXLY_CREATEPAYMENTLINKS__RESET_UPCOMMING_OFFERINGS,
  EXLY_SCHEDULELIST__SET_MEMBERS,
} from "./actions";

const initialState = {
  upcommingOfferings: {
    isLoading: false,
    data: [],
    error: {},
    isFetched: false,
    isFiltering: false,
  },
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case EXLY_CREATEPAYMENTLINKS__SET_UPCOMMING_OFFERINGS: {
      return {
        ...state,
        upcommingOfferings: {
          isLoading: payload.isLoading,
          data: [...payload.data],
          error: payload.error,
          isFetched: payload.isFetched,
          isFiltering: payload.isFiltering,
        },
      };
    }
    case EXLY_CREATEPAYMENTLINKS__SHOW_UPCOMMING_OFFERINGS_LOADING: {
      return {
        ...state,
        upcommingOfferings: {
          ...state.upcommingOfferings,
          isLoading: true,
        },
      };
    }
    case EXLY_CREATEPAYMENTLINKS__RESET_UPCOMMING_OFFERINGS: {
      return {
        ...state,
        upcommingOfferings: {
          isLoading: false,
          data: [],
          error: payload?.error,
          isFetched: false,
        },
      };
    }
    case EXLY_SCHEDULELIST__SET_MEMBERS: {
      return {
        ...state,
        members: {
          data: payload.data,
          error: payload.error,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
