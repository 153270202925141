import ExlyTable from "common/Components/ExlyTable";
import React from "react";
import { RESOURCE_KEYS, orgPermissions } from "utils/OrgPermissions";
import {
  filterConfig,
  getTableConfig,
  tableChipsFormatter,
} from "./refundTableConfig";
import { exportLargeListingData } from "utils/Utils";
import { REPORT_TYPES } from "ui/pages/Reports/constants";
import { useNotifications } from "utils/hooks";
import { is_empty } from "utils/validations";
import { WhatsAppButton } from "webpage-leads/components/CustomColumns";
import {
  FilteredEmptyState,
  StatusField,
} from "../AllTransaction/components/TableFields/TableFields";
import { emptyStateProps } from "./refund.constant";
import {
  getTabsConfig,
  useIsRefundEnabled,
} from "../AllTransaction/utils/AllTransaction.util";

const RefundHistory = (props) => {
  const { notify } = useNotifications();
  const isCustomerDetailsHidden = orgPermissions.isNavigationItemHidden(
    RESOURCE_KEYS.SECTIONS.ALL_CUSTOMERS
  );

  const { isRefundEnabled } = useIsRefundEnabled();

  return (
    <div className="component-wrapper">
      <ExlyTable
        ra_props={{
          ...props,
        }}
        noExportButton={!orgPermissions.canExport()}
        tableTitle="Refund History"
        tabConfig={getTabsConfig({ showRefundTab: isRefundEnabled })}
        selectedTab="refund-history"
        columnConfig={getTableConfig}
        tableChipsFormatter={tableChipsFormatter()}
        layoutProps={{
          paddingDesktop: "0",
          paddingMobile: "0",
          paddingBottom: "68px",
          noMobileBoxShadow: true,
          showFixedBars: true,
        }}
        customHandleExport={({ filterValues, isEmpty }) =>
          exportLargeListingData({
            filterValues,
            reportType: REPORT_TYPES.REFUND_HISTORY,
            notify,
            isEmpty,
          })
        }
        renderPrimaryColumnRightNode={(record) => {
          return (
            <>
              {is_empty(record.customer_phone) ||
              isCustomerDetailsHidden ? null : (
                <WhatsAppButton
                  record={record}
                  source="customer_phone"
                  countryCodeKey="country_code"
                />
              )}
            </>
          );
        }}
        filteredEmptyState={<FilteredEmptyState />}
        borderedFields
        fieldsLeftPadded
        drawerFieldsBordered
        fieldsAlignment="space-between"
        drawerFieldsAlignment="space-between"
        recordFooterVariant="secondary"
        renderSecondaryAction={(record) => <StatusField record={record} />}
        tableFilters={filterConfig()}
        {...emptyStateProps}
      />
    </div>
  );
};

export default RefundHistory;
