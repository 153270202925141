import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  status: {
    color: theme?.palette?.basic?.white,
    fontSize: "14px",
    height: "22px",
    display: "flex",
    alignItems: "center",
    gap: "4px",
    padding: "0 8px",
    borderRadius: "5px",
    overflow: "hidden",
    width: "min-content",
    "& .mui_icon": {
      width: "19.21px",
    },
  },
  floatRight: { float: "right" },
  status__fine_pine: {
    background: theme?.palette?.fine_pine.main,
  },
  status__danger: {
    background: theme?.palette?.basic?.danger_red,
  },
  status__butterscotch_yellow: {
    background: theme?.palette?.butterscotch_yellow.main,
  },

  listingLink: {
    color: theme?.palette?.primary?.main,
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
}));
