import * as React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import makeStyles from "@material-ui/core/styles/makeStyles";

import { MobileModal, Modal } from "@my-scoot/component-library-legacy";
import constants from "constants/constants";

const useStyles = makeStyles((theme) => ({
  summaryInfoRoot: {
    paddingLeft: "24px",
    "& th, td": {
      fontSize: "16px",
      padding: "10px 24px 10px 0",
      borderBottom: `1px solid ${theme?.palette?.secondary?.shade_100}`,
      color: theme?.palette?.basic?.black,
      opacity: 0.85,
    },
  },
  modalRoot: {
    minWidth: "560px",
  },
  modalTitle: {
    fontSize: "18px",
    fontWeight: 700,
    "& span": {
      marginLeft: "12.25px",
    },
    display: "flex",
    alignItems: "center",
  },
  customHeaderRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "142.46px",
    padding: "16px 29.93px 16px 24.49px",
    borderBottom: `1.5px solid ${theme?.palette?.secondary?.shade_100}`,
    boxSizing: "border-box",
  },
  closeIcon: {
    cursor: "pointer",
  },
}));

const LimitedOfferModal = ({ open, onClose, data }) => {
  const classes = useStyles();
  const isDesktop = window.screen.width > constants.mobile_width;

  const SummaryInfo = (
    <div className={classes.summaryInfoRoot}>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Offer name</TableCell>
              <TableCell align="right">
                {data?.feature_discount_info?.code}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Discount value</TableCell>
              <TableCell align="right">
                {data?.feature_discount_info?.value}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );

  return isDesktop ? (
    <Modal
      modalPaperClassName={classes.modalRoot}
      open={open}
      onClose={onClose}
      title="Limited-Time Offer"
      customFooter={<></>}
    >
      {SummaryInfo}
    </Modal>
  ) : (
    <MobileModal
      open={open}
      onClose={onClose}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      header={<span className={classes.modalTitle}>Limited-Time Offer</span>}
      customFooter={<></>}
    >
      {SummaryInfo}
    </MobileModal>
  );
};

export default LimitedOfferModal;
