import React from "react";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { ReactComponent as PendingClockIcon } from "../../../../assets/vectors/pending_clock.svg";
import EmptyListIcon from "assets/vectors/infoModal/Universal_Payment.svg";
import constants from "constants/constants";
import { formKeys } from "features/Crm/modules/Transactions/Transactions.constants";

export const REFUND_STATUS_ENUM = {
  0: "EXECUTED",
  1: "PROCESSED",
  2: "FAILED",
  3: "PENDING",
  4: "PAYMENT PENDING",
};

// TODO: keys should be refund status enum instead of strings
export const REFUND_STATUS = {
  EXECUTED: {
    id: 0,
    title: "Initiated",
    variant: "butterscotch_yellow",
    icon: <PendingClockIcon />,
  },
  PROCESSED: {
    id: 1,
    title: "Refunded",
    variant: "fine_pine",
    icon: null,
  },
  FAILED: {
    id: 2,
    title: "Failed",
    variant: "danger",
    icon: <CancelRoundedIcon className="mui_icon" />,
  },
  PENDING: {
    id: 3,
    title: "Initiated",
    variant: "butterscotch_yellow",
    icon: <PendingClockIcon />,
  },
  "PAYMENT PENDING": {
    id: 4,
    title: "Initiated",
    variant: "butterscotch_yellow",
    icon: <PendingClockIcon />,
  },
};

export const emptyStateProps = {
  page_title: "Refund History",
  content_title: "No refund yet",
  empty_list_icon: EmptyListIcon,
  alt: "no transactions",
  preview_image: constants.preview_all_transactions,
  children: "As soon as we have any refund initiated, it will show up here.",
};

export const FIELD_DATA = {
  customer_name: "customer_name",
  customer_phone: "customer_phone",
  customer_phone_number: "customer_phone",
  customer_email: "customer_email",
  listing_title: "listing_title",
  listing_type: "listing_type",
  transaction_amount: "transaction_amount",
  transaction_id: "transaction_id",
  transaction_date: "transaction_date",
  refund_amount: "refund_amount",
  refund_date: "refund_date",
  reference_no: "reference_no",
  credit_note_link: "credit_note_link",
  receipt_url: "receipt_url",
  status: "status",
  created_at: "created_at",
  transaction_type: formKeys.transactionType.key,
};
export const FORM_DATA = {
  customer_name: "Customer Name",
  customer_phone: "Phone",
  customer_email: "Email Id",
  listing_title: "Offering Name",
  listing_type: "Offering Type",
  transaction_amount: "Transaction Amount",
  transaction_id: "Transaction ID",
  transaction_date: "Transaction Date",
  refund_amount: "Refund Amount",
  refund_date: "Refund Date",
  arn_info: "ARN Info",
  credit_invoice: "Credit note invoice",
  refund_payment_invoice: "Refund payment invoice",
  refund_status: "Refund Status",
  action: "Action",
  transaction_type: formKeys.transactionType.label,
};

export const HEADER_NOTE = {
  credit_note:
    "This invoice is sent to your customers as a credit note stating the amount refunded against the payment made for the booking.",
  refund_payment_invoice:
    "This is the payment invoice that you had made to initiate refund to your customer",
  refund_status: "This may take 2-5 business working days to be processed.",
};
