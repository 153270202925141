import React from "react";
import { is_empty } from "utils/validations";
import {
  TRANSACTIONS_REFUND_OPTIONS,
  TRANS_CONSTANTS,
} from "../../transactions.constants";
import styles from "./InitiateRefund.module.css";
import { Button, Tooltip } from "@my-scoot/component-library-legacy";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { REFUND_PAGES } from "features/Crm/modules/Transactions/modules/Refund/Refund.constants";
import { InitiateRefundModalContext } from "../../AllTransaction";
import { isCustomRefundAmountType } from "../../utils/AllTransaction.util";

const RefundModalFooter = () => {
  const {
    activeRefundPage,
    setActiveRefundPage,
    refundAmountData,
    handlePayNow,
    refundButtonDisabled,
    selectedRefundType,
  } = React.useContext(InitiateRefundModalContext);
  const isCustomRefund = isCustomRefundAmountType(
    TRANSACTIONS_REFUND_OPTIONS[selectedRefundType]
  );

  const {
    payable_amount: payableAmount,
    refund_amount,
    amount_held_with_exly,
    tax_amount,
    rihf_fees_exclusive_of_tx,
  } = refundAmountData;

  // TODO: separate module in features/refund
  const tooltip = (
    <div>
      <table>
        <tbody>
          <tr>
            <td>Amount to be refunded:</td> &nbsp;
            <td>
              ₹{" "}
              {parseFloat(refund_amount)?.toFixed(
                TRANS_CONSTANTS.place_after_decimal
              )}
            </td>
          </tr>
          <tr>
            <td>Amount held with Exly:</td> &nbsp;
            <td>
              ₹{" "}
              {parseFloat(amount_held_with_exly)?.toFixed(
                TRANS_CONSTANTS.place_after_decimal
              )}
            </td>
          </tr>
          <tr>
            <td>Refund handling fees:</td> &nbsp;
            <td>
              ₹{" "}
              {parseFloat(rihf_fees_exclusive_of_tx)?.toFixed(
                TRANS_CONSTANTS.place_after_decimal
              )}
            </td>
          </tr>
          <tr>
            <td>GST:</td> &nbsp;
            <td>
              ₹{" "}
              {parseFloat(tax_amount)?.toFixed(
                TRANS_CONSTANTS.place_after_decimal
              )}
            </td>
          </tr>

          <tr>
            <td>Amount to initiate refund:</td> &nbsp;
            <td>
              ₹{" "}
              {parseFloat(payableAmount)?.toFixed(
                TRANS_CONSTANTS.place_after_decimal
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  const footer = () => {
    if (activeRefundPage === REFUND_PAGES.reviewSelection) {
      return (
        <div
          className={`${styles.footer} d-flex justify-content-center align-items-center`}
        >
          <Button
            onClick={() => setActiveRefundPage(REFUND_PAGES.initiateRefund)}
            color="primary"
            disabled={refundButtonDisabled}
          >
            Confirm & Proceed
          </Button>
        </div>
      );
    }

    const hasRefundAmountData = !is_empty(refundAmountData);

    if (!selectedRefundType) return <div className={styles.footer} />;

    return (
      <div className={styles.footer}>
        <div>
          <span>Amount to initiate refund:</span>
          &nbsp;
          <strong>
            ₹{" "}
            {parseFloat(payableAmount ?? 0)?.toFixed(
              TRANS_CONSTANTS.place_after_decimal
            )}
          </strong>
          &nbsp;
          {hasRefundAmountData && (
            <Tooltip title={tooltip} color="primary" arrow>
              <InfoOutlinedIcon className={styles.tooltip} />
            </Tooltip>
          )}
        </div>
        <Button
          onClick={handlePayNow}
          color="primary"
          disabled={refundButtonDisabled}
          loading={refundButtonDisabled}
        >
          {is_empty(parseInt(payableAmount)) && !isCustomRefund
            ? "Refund Now"
            : "Pay now"}
        </Button>
      </div>
    );
  };

  return footer();
};

export default RefundModalFooter;
