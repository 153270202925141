import {
    List,
    Datagrid,
    TextField, FunctionField, Filter, TextInput, DateInput, Pagination, SelectInput, useListController, NumberInput
} from 'react-admin';
import React from 'react';
import moment from 'moment';
import style from '../Style.module.css';
import { is_empty } from '../../../../utils/validations';
import EmptyList from '../../../modules/EmptyList';
import TransactionHeader from "../../../modules/headers/TransactionHeader";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import constants from '../../../../constants/constants';
import SearchSharpIcon from '@material-ui/icons/SearchSharp';
import { InputAdornment } from '@material-ui/core';
import { getParameterByName } from '../../../../utils/Utils';
import { getUserCurrencySymbol } from '../../../../utils/AuthUtil';

const PastRefunds = (props) => {
    const { filterValues, loaded, data } = useListController(props);

    const ListPagination = props => <Pagination rowsPerPageOptions={[]} {...props} />;

    const MoneyField = ({ record = {}, source }) => {
        return (<span>
            {`${getUserCurrencySymbol()} ${record[source]}`}
        </span>);
    }

    const PhoneField = ({ record = {}, source }) => {
        return (<span>
            { is_empty(record[source]) ? 'N/A' : <a
                target="_blank"
                rel="noopener noreferrer"
                className={style.whatsapp_number}
                href={`https://api.whatsapp.com/send?phone=${record.country_code}${record[source]}`}>
                {record.country_code}&nbsp;{record[source]}
                <WhatsAppIcon className={style.whatsapp_icon} />
            </a>}
        </span>);
    }


    const PostFilter = (props) => (
        <Filter {...props}>
            <TextInput variant="outlined" className={`mobile_filter`} label="Name" source="refunded_user_name" alwaysOn resettable InputProps={{
                        endAdornment: <InputAdornment position="end"> <div className={style.searchIcon}><SearchSharpIcon /></div></InputAdornment>}} />
            <TextInput variant="outlined" className={`mobile_filter`} label="Email" source="refunded_user__email__icontains" alwaysOn resettable InputProps={{
                        endAdornment: <InputAdornment position="end"> <div className={style.searchIcon}><SearchSharpIcon /></div></InputAdornment>}} />
            <TextInput variant="outlined" className={`mobile_filter`} label="Mobile number" source="refunded_user__phone_number__contains" alwaysOn resettable InputProps={{
                        endAdornment: <InputAdornment position="end"> <div className={style.searchIcon}><SearchSharpIcon /></div></InputAdornment>}} />
            <SelectInput variant="outlined" className={`mobile_filter`} label="Session Type" choices={constants.schedule_type}
                source='listing__type' alwaysOn optionValue="id" />
            <DateInput variant="outlined" className={`mobile_filter`} label="From" source="created_at__start_range" alwaysOn />
            <DateInput variant="outlined" className={`mobile_filter`} label="To" source="created_at__end_range" alwaysOn />
            <NumberInput variant="outlined" className={`mobile_filter`} label="Minimum Amount" source="refund_amount__gte" alwaysOn resettable InputProps={{
                        endAdornment: <InputAdornment position="end"> <div className={style.searchIcon}><SearchSharpIcon /></div></InputAdornment>}} />
            <NumberInput variant="outlined" className={`mobile_filter`} label="Maximum Amount" source="refund_amount__lte" alwaysOn resettable InputProps={{
                        endAdornment: <InputAdornment position="end"> <div className={style.searchIcon}><SearchSharpIcon /></div></InputAdornment>}} />
            <TextInput variant="outlined" className={`mobile_filter`} label="Session Name" source="listing__title__icontains" alwaysOn resettable InputProps={{
                        endAdornment: <InputAdornment position="end"> <div className={style.searchIcon}><SearchSharpIcon /></div></InputAdornment>}} />
        </Filter>
    );

    return (
        <div className={'parent'}>

            <div className={` ${style.sticky}`}>
                <TransactionHeader active={2} />
            </div>
            {is_empty(data) && loaded && is_empty(props.location.search) && is_empty(filterValues) ? <EmptyList module={'transaction'} module_text={'refund'}  /> : <>{!is_empty(filterValues) || !is_empty(getParameterByName('custom_filter'))?
                <div className={style.clear_filter} onClick={() => {
                    window.location.href = `${window.location.origin}/#/payments/exly/refunds/past/list`;
                    window.location.reload();
                }} ><span className='text-danger'>Clear Filter</span></div> : null}
                <List perPage={50}{...props} bulkActionButtons={false}
                    pagination={<ListPagination />}
                    filters={<PostFilter />}
                >
                    <Datagrid isRowSelectable={record => false} >
                        <TextField source="customer_name" emptyText={'N/A'} />
                        <PhoneField label={'Phone'} source="phone_number" emptyText={'N/A'} />
                        <TextField source="customer_email" emptyText={'N/A'} />
                        <TextField source="session_name" emptyText={'N/A'} />
                        <MoneyField source="amount" label="Amount Refunded" emptyText={'N/A'} />
                        <FunctionField label="Refund date" render={record => `${moment.unix(record.refund_date).utc().format('DD/MM/YY hh:mm A')}`} />
                        <FunctionField label="Session date" render={record => `${moment.unix(record.session_datetime).utc().format('DD/MM/YY hh:mm A')}`} />
                    </Datagrid>
                </List>
            </>}
        </div>
    );
}

export default PastRefunds;